import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { AppDispatch } from '../../store/store'
import './Cart.scss'
import Cutlery from '../../assets/cutlery.png'
import { cartActions } from '../../store/reducers/cart'
import { ProductsCarousel } from '../../components/productsCarousel/ProductsCarousel'
import { getTotal } from '../../utils/getTotal'
import { Promocode } from '../../components/promocode/Promocode'
import { getUniqueProducts } from '../../utils/uniqueProducts'
import { CartProduct } from '../../components/cartProduct/CartProduct'
import '../../components/cartProduct/CartProduct.scss'
import { useActions } from '../../hooks/useActions'
import { Placeholder } from '../../components/placeholder/Placeholder'
import { Helmet } from 'react-helmet'
import ym from 'react-yandex-metrika'
export const Cart = () => {
    const { currentConcept } = useTypedSelector((state) => state.conceptReducer)
    const { cart, cutlery } = useTypedSelector((state) => state.cartReducer)
    const { products } = useTypedSelector((state) => state.productReducer)
    const { slug } = useParams()
    const dispatch = useDispatch<AppDispatch>()
    const { getConceptById } = useActions()
    // useEffect(() => {
    //     if (!currentConcept && slug) {
    //         getConceptById(slug)
    //     }
    // }, [])

    const navigate = useNavigate()
    const unique = useMemo(() => getUniqueProducts(cart, products), [cart, products])

    if (!currentConcept || products.length === 0) return <Placeholder />
    return (
        <>
            <Helmet>
                <title>Корзина</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='cart-page'>
                <h1 className='cart-page__header'>Корзина</h1>
                {/* <CurrentConceptCard currentConcept={currentConcept} /> */}
                <button className='cart-page__clear' onClick={() => dispatch(cartActions.clear())}>
                    Очистить корзину
                </button>
                {cart.length === 0 ? (
                    <p className='cart-page__empty'>Корзина пуста</p>
                ) : (
                    cart.map((_productInCart, i) => {
                        const _product =
                            _productInCart.productObj ??
                            products.find((p) => p._id === _productInCart.product)
                        return _product ? (
                            <CartProduct
                                key={`${_product._id} ${i}`}
                                product={_product}
                                productInCart={_productInCart}
                            />
                        ) : null
                    })
                )}
                <div className='cart-item'>
                    <img className='cart-item__image' src={Cutlery} alt={'Cutlery'} />
                    <div className='cart-item__name'>
                        <p>Приборы</p>
                        <small>Вилка, нож</small>
                    </div>
                    <div className='cart-item__counter'>
                        <button
                            className='cart-item__counter--btn-counter'
                            onClick={() => dispatch(cartActions.decreaseCutlery())}>
                            -
                        </button>
                        <span>{cutlery.amount}</span>
                        <button
                            className='cart-item__counter--btn-counter'
                            onClick={() => dispatch(cartActions.increaseCutlery())}>
                            +
                        </button>
                    </div>
                    <div className='cart-item__price'>0₽</div>
                </div>
                <ProductsCarousel products={unique} />
                <div className='cart-page__total'>
                    <span>Итого</span>
                    <span>{getTotal(products, cart)}₽</span>
                </div>
                <div className='cart-page__actions'>
                    <Promocode />
                    <button
                        className='cart-page__actions--submit'
                        onClick={() => {
                            ym('reachGoal', 'INITIATE_CHECKOUT')
                            navigate('/order')
                        }}>
                        Оформить
                    </button>
                </div>
            </div>
        </>
    )
}

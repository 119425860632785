import { ICart, ICartModifiers } from '../interfaces/Cart'
import { IDeliveryCheck } from '../interfaces/Order'
import { IProduct } from '../interfaces/Product'

export const getTotal = (products: IProduct[], cart: ICart[], order?: IDeliveryCheck | null) => {
    let t = 0 
    if(products.length > 0) {
        cart.forEach(p => {
            const product = products.find(i => i._id === p.product)
            
            const count = p.count
            let price = 0
            if(product)
                price = getPrice(product.price, product.weight, p.modifiers) * count
            if(!p.isPromocode) 
                t += price
        })
    }
    if(order && order.deliveryPrice && order.minOrderSum! > t) {
        console.log(t, order)
        t += order.deliveryPrice
    }
    // if(order && order.deliveryPrice) t += t > order.minOrderSum! ? 0 : order.deliveryPrice
    return t
}

interface IWeight {
    min: number
    full: number
}
export const getPrice = (price: number, weight: IWeight | null, options: ICartModifiers[]) => {
    let total = price

    if(options.length > 0) {
        options.forEach(o => {
            o.option.forEach(op => total += op.price)
        })
    }
    if(weight)
        if(weight.min > 0) total *= weight.min

    return Math.ceil(total)
}
import React from 'react'
import { Helmet } from 'react-helmet'
import './Agreement.scss'
export const Agreement = () => {
    return (
        <>
            <Helmet>
                <meta
                    name='description'
                    content='Быстрая доставка еды в Красноярске. Неаполитанской пиццы и европеейской кухни.'
                />
                <title>Политика конфиденциальности</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='agreement'>
                <div>
                    <h3>Политика конфиденциальности</h3>
                </div>
                <br />

                <p>
                    <span>
                        Настоящая Политика конфиденциальности персональных данных (далее – Политика)
                        разработана в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О
                        персональных данных», вступает в силу с момента ее опубликования на Сайте и
                        действует бессрочно до замены ее новой Политикой. Политика действует в
                        отношении всей информации, которую сайт, расположенный по адресу{' '}
                        <a href='https://vmdelivery.ru' target={'_blank'}>
                            https://vmdelivery.ru{' '}
                        </a>
                        (далее Сайт), может получить о Пользователе во время использования Сайта,
                        программ и продуктов Сайта.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        В настоящей Политике конфиденциальности используются следующие термины:
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        1.1.1. «Сайт» – совокупность web-страниц с повторяющимся дизайном,
                        объединенных по смыслу и навигацией и находящихся на одном web-сервере.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        1.1.2. «Администрация сайта» – сотрудники, уполномоченные на управления
                        сайтом, действующие от имени ООО "ЗАПАД" ИНН 2464139285, которые организуют
                        и (или) осуществляет обработку персональных данных, а также определяет цели
                        обработки персональных данных, состав персональных данных, подлежащих
                        обработке, действия (операции), совершаемые с персональными данными.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        1.1.3. «Персональные данные» - любая информация, относящаяся к прямо или
                        косвенно определённому, или определяемому физическому лицу (субъекту
                        персональных данных).
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        1.1.4. «Обработка персональных данных» - любое действие (операция) или
                        совокупность действий (операций), совершаемых с использованием средств
                        автоматизации или без использования таких средств с персональными данными,
                        включая сбор, запись, систематизацию, накопление, хранение, уточнение
                        (обновление, изменение), извлечение, использование, передачу
                        (распространение, предоставление, доступ), обезличивание, блокирование,
                        удаление, уничтожение персональных данных.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        1.1.5. «Конфиденциальность персональных данных» - обязательное для
                        соблюдения Оператором или иным получившим доступ к персональным данным лицом
                        требование не допускать их распространения без согласия субъекта
                        персональных данных или наличия иного законного основания.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        1.1.6. «Пользователь сайта (далее Пользователь)» – лицо, имеющее доступ к
                        Сайту, посредством сети Интернет и использующее Сайт.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        1.1.7. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и
                        хранимый на компьютере пользователя, который веб-клиент или веб-браузер
                        каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть
                        страницу соответствующего сайта.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        1.1.8. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети,
                        построенной по протоколу IP.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ОБЩИЕ ПОЛОЖЕНИЯ</span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        2.1. Использование Пользователем Сайта означает согласие с настоящей
                        Политикой конфиденциальности и условиями обработки персональных данных
                        Пользователя.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        2.2. В случае несогласия с условиями Политики конфиденциальности
                        Пользователь должен прекратить использование Сайта.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        2.3. Настоящая Политика конфиденциальности применяется только к Сайту.
                        Интернет-магазин не контролирует и не несет ответственность за сайты третьих
                        лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        2.4. Администрация сайта не проверяет достоверность персональных данных,
                        предоставляемых Пользователем.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        3.1. Настоящая Политика конфиденциальности устанавливает обязательства
                        Администрации сайта по неразглашению и обеспечению режима защиты
                        конфиденциальности персональных данных, которые Пользователь предоставляет
                        по запросу Администрации сайта при регистрации на.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        3.2. Персональные данные, разрешённые к обработке в рамках настоящей
                        Политики конфиденциальности, предоставляются Пользователем путём заполнения
                        регистрационной формы на Сайте в разделе (адрес и название раздела/разделов)
                        и включают в себя следующую информацию:
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>3.2.1. фамилию, имя, отчество Пользователя;</span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>3.2.2. контактный телефон Пользователя;</span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>3.2.3. адрес электронной почты (e-mail).</span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        3.3. Сайт защищает Данные, которые автоматически передаются при посещении
                        страниц.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        3.3.1. Отключение Пользователем cookies может повлечь невозможность доступа
                        к частям сайта, требующим авторизации.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        3.3.2. Сайт осуществляет сбор статистики об IP-адресах своих посетителей.
                        Данная информация используется с целью выявления и решения технических
                        проблем.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        3.4. Любая иная персональная информация неоговоренная выше (используемые
                        браузеры и операционные системы и т.д.) подлежит надежному хранению и
                        нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и
                        5.3. настоящей Политики конфиденциальности.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ
                        ПОЛЬЗОВАТЕЛЯ
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        4.1. Персональные данные Пользователя Администрация сайта может использовать
                        в целях:
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>4.1.1. Идентификации Пользователя, зарегистрированного на сайте.</span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам
                        Сайта.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        4.1.3. Установления с Пользователем обратной связи, включая направление
                        уведомлений, запросов, касающихся использования Сайта, оказания услуг,
                        обработка запросов и заявок от Пользователя.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        4.1.4. Определения места нахождения Пользователя для обеспечения
                        безопасности, предотвращения мошенничества.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        4.1.5. Подтверждения достоверности и полноты персональных данных,
                        предоставленных Пользователем.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        4.1.6. Предоставления Пользователю эффективной клиентской и технической
                        поддержки при возникновении проблем, связанных с использованием Сайта.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        4.1.7. Предоставления Пользователю с его согласия новостной рассылки и иных
                        сведений от имени Сайта или от имени партнеров Сайта.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        4.1.8. Осуществления рекламной деятельности с согласия Пользователя.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        5.&nbsp;&nbsp;&nbsp; СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        5.1. Обработка персональных данных Пользователя осуществляется без
                        ограничения срока, любым законным способом, в том числе в информационных
                        системах персональных данных с использованием средств автоматизации или без
                        использования таких средств.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        5.2. Пользователь соглашается с тем, что Администрация сайта вправе
                        передавать персональные данные третьим лицам.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        5.3. Персональные данные Пользователя могут быть переданы уполномоченным
                        органам государственной власти Российской Федерации только по основаниям и в
                        порядке, установленным законодательством Российской Федерации.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        5.4. При утрате или разглашении персональных данных Администрация сайта
                        информирует Пользователя об утрате или разглашении персональных данных.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        5.5. Администрация сайта принимает необходимые организационные и технические
                        меры для защиты персональной информации Пользователя от неправомерного или
                        случайного доступа, уничтожения, изменения, блокирования, копирования,
                        распространения, а также от иных неправомерных действий третьих лиц.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        5.6. Администрация сайта совместно с Пользователем принимает все необходимые
                        меры по предотвращению убытков или иных отрицательных последствий, вызванных
                        утратой или разглашением персональных данных Пользователя.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>6.&nbsp;&nbsp;&nbsp; ОБЯЗАТЕЛЬСТВА СТОРОН</span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>6.1. Пользователь обязуется:</span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        6.1.1. Предоставить информацию о персональных данных, необходимую для
                        пользования Сайтом.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        6.1.2. Обновить, дополнить предоставленную информацию о персональных данных
                        в случае изменения данной информации.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>6.2. Администрация сайта обяуется:</span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        6.2.1. Использовать полученную информацию исключительно для целей, указанных
                        в п. 4 настоящей Политики конфиденциальности.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не
                        разглашать без предварительного письменного разрешения Пользователя, а также
                        не осуществлять продажу, обмен, опубликование, либо разглашение иными
                        возможными способами переданных персональных данных Пользователя, за
                        исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        6.2.3. Принимать меры предосторожности для защиты конфиденциальности
                        персональных данных Пользователя согласно порядку, обычно используемого для
                        защиты такого рода информации в существующем деловом обороте.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        6.2.4. Осуществить блокирование персональных данных, относящихся к
                        соответствующему Пользователю, с момента обращения или запроса Пользователя,
                        или его законного представителя либо уполномоченного органа по защите прав
                        субъектов персональных данных на период проверки, в случае выявления
                        недостоверных персональных данных или неправомерных действий.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>7.&nbsp;&nbsp;&nbsp; ОТВЕТСТВЕННОСТЬ СТОРОН</span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        7.1. Администрация сайта, не исполнившая свои обязательства, несёт
                        ответственность за убытки, понесённые Пользователем в связи с неправомерным
                        использованием персональных данных, в соответствии с законодательством
                        Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2.,
                        5.3. и 7.2. настоящей Политики Конфиденциальности.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        7.2. В случае утраты или разглашения Конфиденциальной информации
                        Администрация сайта не несёт ответственность, если данная конфиденциальная
                        информация:
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>7.2.1. Стала публичным достоянием до её утраты или разглашения.</span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        7.2.2. Была получена от третьей стороны до момента её получения
                        Администрацией сайта.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>7.2.3. Была разглашена с согласия Пользователя.</span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>8.&nbsp;&nbsp;&nbsp; РАЗРЕШЕНИЕ СПОРОВ</span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        8.1. До обращения в суд с иском по спорам, возникающим из отношений между
                        Пользователем сайта и Администрацией сайта, обязательным является
                        предъявление претензии (письменного предложения о добровольном
                        урегулировании спора).
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        8.2. Получатель претензии в течение 30 календарных дней со дня получения
                        претензии, письменно уведомляет заявителя претензии о результатах
                        рассмотрения претензии.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        8.3. При не достижении соглашения спор будет передан на рассмотрение в
                        судебный орган в соответствии с действующим законодательством Российской
                        Федерации.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        8.4. К настоящей Политике конфиденциальности и отношениям между
                        Пользователем и Администрацией сайта применяется действующее
                        законодательство Российской Федерации.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>9.&nbsp;&nbsp;&nbsp; ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        9.1. Администрация сайта вправе вносить изменения в настоящую Политику
                        конфиденциальности без согласия Пользователя.
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        9.2. Все предложения или вопросы по настоящей Политике конфиденциальности
                        следует сообщать через форму обратной связи по адресу{' '}
                        <a href='mailto:dostavkakrsk@yandex.ru'>dostavkakrsk@yandex.ru</a>
                    </span>
                </p>

                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <span>
                        9.3. Действующая Политика конфиденциальности размещена на странице по адресу{' '}
                        <a href='https://vmdelivery.ru/agreement'>
                            https://vmdelivery.ru/agreement
                        </a>
                    </span>
                </p>

                <br />
                <br />
            </div>
        </>
    )
}

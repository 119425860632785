import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { CartProduct } from '../../components/cartProduct/CartProduct'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { cartActions } from '../../store/reducers/cart'
import { orderActions } from '../../store/reducers/order'
import { getTotal } from '../../utils/getTotal'
import './ThanksForOrder.scss'
import Cutlery from '../../assets/cutlery.png'
import { Helmet } from 'react-helmet'

export const ThanksForOrder = () => {
    const { cart, cutlery } = useTypedSelector((state) => state.cartReducer)
    const { products } = useTypedSelector((state) => state.productReducer)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        localStorage.removeItem('vita-cart')
        return () => {
            dispatch(orderActions.clearOrder())
            dispatch(cartActions.clear())
        }
    }, [])
    if (cart.length === 0) return <Navigate to={'/'} />
    return (
        <>
            <Helmet>
                <meta
                    name='description'
                    content='Быстрая доставка еды в Красноярске. Неаполитанской пиццы и европеейской кухни.'
                />
                <title>Спасибо за заказ!</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='thanks-for-order'>
                <h1>Спасибо за Ваш заказ!</h1>
                <p>Мы уже обрабатываем ваш заказ</p>
                <div className='thanks-for-order__cart'>
                    {cart.map((_productInCart, i) => {
                        const _product = products.find((p) => p._id === _productInCart.product)
                        return _product ? (
                            <CartProduct
                                key={`${_product._id} ${i}`}
                                product={_product}
                                productInCart={_productInCart}
                                readOnly
                            />
                        ) : null
                    })}
                    {cutlery.amount > 0 && (
                        <div className='cart-item'>
                            <img className='cart-item__image' src={Cutlery} alt={'Cutlery'} />
                            <div className='cart-item__name'>
                                <p>Приборы</p>
                                <small>Вилка, нож</small>
                            </div>
                            <div className='cart-item__counter'>
                                <span>{cutlery.amount} шт.</span>
                            </div>
                            <div className='cart-item__price'>0₽</div>
                        </div>
                    )}
                </div>
                <div className='thanks-for-order__total'>
                    <span>Итого</span>
                    <span>{getTotal(products, cart)}₽</span>
                </div>
                <button className='thanks-for-order__back' onClick={() => navigate('/')}>
                    На главную
                </button>
            </div>
        </>
    )
}

import React, { useState } from 'react'
import { RegisteredForm } from '../registeredForm/RegisteredForm'
import { PhoneInput } from '../UI/phoneInput/PhoneInput'
import './OrderPhone.scss'
export const OrderPhone = () => {
    const [phone, setPhone] = useState('')
    const [isRegistered, setIsRegistered] = useState(true)

    if (!isRegistered) return <RegisteredForm />
    return (
        <div className='order-phone'>
            <h1 className='order-phone__header'>Подтверждение телефона</h1>
            {!phone ? (
                <p className='order-phone__desc'>
                    Введите свой номер телефона: это поможет нам
                    идентифицировать вас для того, чтобы следующий заказ был ещё
                    более удобным!
                </p>
            ) : (
                <p className='order-phone__desc'>
                    Мы отправили код потверждения на номер {phone + ' '}
                    введите его в поле ниже
                </p>
            )}

            <PhoneInput
                setIsRegistered={setIsRegistered}
                setSuccessCode={(_phone: string) => {
                    setPhone(_phone)
                }}
            />
        </div>
    )
}

import { ICart, ICartModifiers } from '../interfaces/Cart'
import { IOrderProduct, IOrderProductModifier } from '../interfaces/Order'
import { IProduct } from '../interfaces/Product'

export const prepareOrder = (cart: ICart[], products: IProduct[], cutleryNum: number) => {
    // console.log(cart)
    const _products: IOrderProduct[] = cart.map((c) => ({
        id: c.productObj ? c.productObj._id : (products.find(p => p._id === c.product)?._id || ''),
        code: c.productObj ? c.productObj.code : (products.find(p => p._id === c.product)?.code || ''),
        amount: c.count,
        modifiers: prepareMods(c.modifiers)
    }))
    if(cutleryNum > 0) _products.push({
        id: '64254c6e73651f4f4acf54ff',
        code: '01082',
        amount: cutleryNum,
        modifiers: []
    })
    return _products
}

const prepareMods = (modifiers: ICartModifiers[]) => {
    let _modifiers:IOrderProductModifier[] = []
    modifiers.forEach((m) => {
        const mods = m.option.map(o => ({
            id: o._id,
            amount: 1
        }))
        _modifiers = [..._modifiers, ...mods]
    })
    return _modifiers
}
import React, { FC, useEffect } from 'react'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { ICategory } from '../../interfaces/Category'
import { IProduct } from '../../interfaces/Product'
import { findSubCategories } from './MenuItems'
import './MenuItems.scss'
import { SubmenuItems } from './SubmenuItems'

interface SubsOption {
    _id: string
    name: string
    products: IProduct[]
}

interface MenuItemsMobileProps {
    restId: string
    setActiveElement: (id: string) => void
}
export const MenuItemsMobile: FC<MenuItemsMobileProps> = ({ restId, setActiveElement }) => {
    const { categories, products } = useTypedSelector((state) => state.productReducer)
    const { currentConcept } = useTypedSelector((state) => state.conceptReducer)

    const { getProducts } = useActions()
    useEffect(() => {
        if (currentConcept && products.length === 0) getProducts(currentConcept._id)
    }, [currentConcept])

    useEffect(() => {
        if (categories.length === 0) return
        const items = document.querySelectorAll('.menu-items__header')
        // console.log(items)

        const scrollTracking = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((e) => {
                if (e.isIntersecting) {
                    setActiveElement(e.target.parentElement!.id)
                }
            })
        }
        const height = window.innerHeight - 100

        const observer = new IntersectionObserver(scrollTracking, {
            threshold: 0,
            rootMargin: `-30px 0px -${height}px 0px`,
        })
        items.forEach((i) => observer.observe(i))

        return () => {
            items.forEach((i) => observer.unobserve(i))
        }
    }, [categories])

    const getSubs = (cat: ICategory) => {
        const _subs: SubsOption[] = []
        const _subsCat = findSubCategories(cat._id, categories)
        if (_subsCat.length !== 0) {
            _subs.push(
                ..._subsCat.map((s) => ({
                    _id: s._id,
                    name: s.name,
                    products: products.filter((p) => p.categoryId === s._id),
                }))
            )
        } else {
            _subs.push({
                _id: cat._id,
                name: '',
                products: products.filter((p) => p.categoryId === cat._id),
            })
        }
        return _subs
    }
    return (
        <>
            {categories
                .filter((cat) => !cat.parentCategory)
                .map((cat, i) => (
                    <div id={`category_${cat._id}`} key={cat._id} className='menu-items'>
                        <h1 className='menu-items__header'>{cat.name}</h1>
                        <p className='menu-items__desc'></p>
                        {getSubs(cat).map((data) => (
                            <SubmenuItems
                                key={data._id}
                                name={data.name}
                                products={data.products.filter(
                                    (p) => p.isDisabled === false && p.isDeleted === false
                                )}
                            />
                        ))}
                    </div>
                ))}
        </>
    )
}

import { AxiosError } from 'axios'
import React, { useEffect, useReducer, useRef } from 'react'
import { PatternFormat } from 'react-number-format'
import { CustomerSevice } from '../../api/CustomerService'
import { useActions } from '../../hooks/useActions'
import { DateTime } from 'luxon'
import './RegisteredForm.scss'
import { useLocation, useNavigate } from 'react-router-dom'

interface IinitState {
    name: string
    surname: string
    dob: string
    sex: 0 | 1
    email: string
}
const initState: IinitState = {
    name: '',
    surname: '',
    dob: '',
    sex: 0,
    email: '',
}
const reducer = (state: IinitState, action: any) => {
    switch (action.type) {
        case 'setname':
            return {
                ...state,
                name: action.payload,
            }
        case 'setsurname':
            return {
                ...state,
                surname: action.payload,
            }
        case 'setdob':
            return {
                ...state,
                dob: action.payload,
            }
        case 'setsex':
            return {
                ...state,
                sex: action.payload,
            }
        case 'setemail':
            return {
                ...state,
                email: action.payload,
            }
        default:
            return state
    }
}

const getError = (code: string) => {
    switch (code) {
        case 'email':
            return 'Укажите корректный E-mail'
        case 'dob':
            return 'Укажите корректную дату рождения'
        case 'name':
            return 'Укажите имя и/или фамилию'
        default:
            return ''
    }
}
export const RegisteredForm = () => {
    const [state, dispatch] = useReducer(reducer, initState)
    const btnRef = useRef<HTMLButtonElement>(null)
    const errorRef = useRef<HTMLParagraphElement>(null)
    const { getProfile } = useActions()
    const nav = useNavigate()

    const onSubmit = async () => {
        if (btnRef.current) {
            btnRef.current.disabled = true
            document.body.style.cursor = 'wait'
            btnRef.current.dataset.loading = 'true'
        }
        if (!DateTime.fromFormat(state.dob, 'dd.MM.yyyy').isValid) {
            if (errorRef.current) errorRef.current.innerHTML = getError('dob')
            if (btnRef.current) {
                btnRef.current.disabled = false
                btnRef.current.dataset.loading = 'false'
            }
            document.body.style.cursor = 'default'
            return
        }
        if (state.name.trim().length < 2 || state.surname.trim().length < 2) {
            if (errorRef.current) errorRef.current.innerHTML = getError('name')
            if (btnRef.current) {
                btnRef.current.disabled = false
                btnRef.current.dataset.loading = 'false'
            }
            document.body.style.cursor = 'default'
            return
        }
        try {
            if (errorRef.current) errorRef.current.innerHTML = ''
            // if (btnRef.current) {
            //     btnRef.current.disabled = true
            //     document.body.style.cursor = 'wait'
            // }
            const api = new CustomerSevice()
            const dob_split = state.dob.split('.')
            const _response = await api.signup(
                state.name,
                state.surname,
                state.email.trim().length > 0 ? state.email : null,
                `${dob_split[2]}-${dob_split[1]}-${dob_split[0]}`,
                state.sex === 1 ? 'male' : 'female'
            )
            console.log(_response)
            getProfile()
            nav(-1)
        } catch (error) {
            const e = error as Error
            const message = JSON.parse(e.message)
            const errorMessages: string[] = []
            message.detail.forEach((d: any) =>
                errorMessages.push(getError(d.loc[1]))
            )
            if (errorRef.current)
                errorRef.current.innerHTML = errorMessages.join('<br />')
        } finally {
            if (btnRef.current) {
                btnRef.current.disabled = false
                btnRef.current.dataset.loading = 'false'
            }
            document.body.style.cursor = 'default'
            // nav('/')
        }
    }
    return (
        <div className='register-form'>
            <h1 className='register-form__header'>Давайте познакомимся!</h1>
            <p className='register-form__desc'>
                Заполните краткую анкету, и следующий заказ будет быстрее!
            </p>
            <div className='register-form__content'>
                <div className='register-form__content-fio'>
                    <input
                        value={state.name}
                        placeholder='Имя'
                        onChange={(e) =>
                            dispatch({
                                type: 'setname',
                                payload: e.target.value,
                            })
                        }
                    />
                    <input
                        value={state.surname}
                        placeholder='Фамилия'
                        onChange={(e) =>
                            dispatch({
                                type: 'setsurname',
                                payload: e.target.value,
                            })
                        }
                    />
                </div>
                <div className='register-form__email'>
                    <input
                        value={state.email}
                        placeholder='user@example.com'
                        type={'email'}
                        onChange={(e) =>
                            dispatch({
                                type: 'setemail',
                                payload: e.target.value,
                            })
                        }
                    />
                </div>
                <div className='register-form__content-other-info'>
                    <div className='input-dob'>
                        <label>Дата рождения</label>
                        <PatternFormat
                            // type={'text'}
                            format='##.##.####'
                            value={state.dob}
                            placeholder='дд.мм.гггг'
                            data-accept='\d'
                            onChange={(e) =>
                                dispatch({
                                    type: 'setdob',
                                    payload: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className='input-sex'>
                        <label>Пол</label>
                        <div>
                            <div>
                                <input
                                    name='sex'
                                    id='sex-male'
                                    type={'radio'}
                                    checked={state.sex === 1}
                                    onChange={(e) =>
                                        dispatch({
                                            type: 'setsex',
                                            payload: e.target.checked ? 1 : 0,
                                        })
                                    }
                                />
                                <label htmlFor='sex-male'>Мужской</label>
                            </div>
                            <div>
                                <input
                                    name='sex'
                                    id='sex-female'
                                    type={'radio'}
                                    checked={state.sex === 0}
                                    onChange={(e) =>
                                        dispatch({
                                            type: 'setsex',
                                            payload: e.target.checked ? 0 : 1,
                                        })
                                    }
                                />
                                <label htmlFor='sex-female'>Женский</label>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    ref={btnRef}
                    className='register-form__content-btn-submit'
                    onClick={onSubmit}>
                    Продолжить
                </button>
                <p ref={errorRef} className='register-form__content-error'></p>
            </div>
        </div>
    )
}

import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Navigate } from 'react-router-dom'
import { RegisteredForm } from '../../components/registeredForm/RegisteredForm'
import { PhoneInput } from '../../components/UI/phoneInput/PhoneInput'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import './SignIn.scss'
export const SignIn = () => {
    const [phone, setPhone] = useState('')
    const [isRegistered, setIsRegistered] = useState(true)
    const { customer } = useTypedSelector((state) => state.customerReducer)
    if (customer) return <Navigate to={'/'} />
    if (!isRegistered)
        return (
            <div className='signin'>
                <RegisteredForm />
            </div>
        )
    return (
        <>
            <Helmet>
                <meta
                    name='description'
                    content='Быстрая доставка еды в Красноярске. Неаполитанской пиццы и европеейской кухни.'
                />
                <title>Вход</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='signin'>
                <h1 className='signin__header'>Вход</h1>
                {!phone ? null : (
                    <p className='signin__desc'>
                        Мы отправили код потверждения на номер {phone + ' '}
                        введите его в поле ниже
                    </p>
                )}

                <PhoneInput
                    setIsRegistered={setIsRegistered}
                    setSuccessCode={(_phone: string) => {
                        setPhone(_phone)
                    }}
                />
            </div>
        </>
    )
}

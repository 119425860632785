import React from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { getPrice, getTotal } from '../../utils/getTotal'
import './OrderCard.scss'

export const OrderCard = () => {
    const { cart, cutlery } = useTypedSelector((s) => s.cartReducer)
    const { orderCheck, order } = useTypedSelector((s) => s.orderReducer)
    const { products } = useTypedSelector((s) => s.productReducer)
    const getProduct = (id: string) => products.find((p) => p._id === id)
    return (
        <div className='order-card'>
            <p className='order-card__header'>Ваш заказ</p>
            <div className='order-card__content'>
                {cart.map((c) => {
                    const _product = c.productObj ?? getProduct(c.product)
                    return (
                        <div key={c.id} className='order-card__content-item'>
                            <div className='order-card__content-item--info'>
                                <span>{_product?.name}</span>
                                <small>
                                    {c.modifiers
                                        .map((m) => m.option.map((op) => op.name).join(', '))
                                        .join(', ')}
                                </small>
                            </div>
                            <div className='order-card__content-item--count'>{c.count} шт.</div>
                            <div className='order-card__content-item--price'>
                                {getPrice(_product!.price, _product!.weight, c.modifiers)} ₽
                            </div>
                        </div>
                    )
                })}
                {orderCheck && orderCheck.areaName && (
                    <div className='order-card__content-item'>
                        <div className='order-card__content-item--info'>
                            <span>Доставка</span>
                        </div>
                        <div className='order-card__content-item--count'>1 шт.</div>
                        <div className='order-card__content-item--price'>
                            {orderCheck.minOrderSum! > getTotal(products, cart)
                                ? orderCheck.deliveryPrice
                                : 0}{' '}
                            ₽
                        </div>
                    </div>
                )}
                {cutlery.amount > 0 && (
                    <div className='order-card__content-item'>
                        <div className='order-card__content-item--info'>
                            <span>Приборы</span>
                            <small>Вилка, нож</small>
                        </div>
                        <div className='order-card__content-item--count'>{cutlery.amount} шт.</div>
                    </div>
                )}
                {order.withdrawBonuses > 0 && (
                    <div className='order-card__content-item'>
                        <div className='order-card__content-item--info'>
                            <span>Бонусы</span>
                        </div>
                        <div className='order-card__content-item--count'></div>
                        <div className='order-card__content-item--price'>
                            -{order.withdrawBonuses}
                        </div>
                    </div>
                )}
                <div className='order-card__content-total'>
                    Итого: {getTotal(products, cart, orderCheck) - order.withdrawBonuses}₽
                </div>
            </div>
        </div>
    )
}

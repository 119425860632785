import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { OrderSevice } from '../../api/OrderService'
import { CartProduct } from '../../components/cartProduct/CartProduct'
import { IOrder } from '../../interfaces/Order'
import { getWeight } from '../../utils/getWeight'
import '../success/SuccessOrder.scss'
import '../../components/cartProduct/CartProduct.scss'
import { useDispatch } from 'react-redux'
import { orderActions } from '../../store/reducers/order'
import { cartActions } from '../../store/reducers/cart'
export const FailOrder = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        localStorage.removeItem('vita-cart')
        return () => {
            dispatch(orderActions.clearOrder())
            dispatch(cartActions.clear())
        }
    }, [])
    return (
        <>
            <Helmet>
                <meta
                    name='description'
                    content='Быстрая доставка еды в Красноярске. Неаполитанской пиццы и европеейской кухни.'
                />
                <title>Ошибка заказа</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='thanks-for-order'>
                <h1>Произошла ошибка при оплате заказа!</h1>
                {/* <p>Мы уже обрабатываем ваш заказ</p> */}
                <button className='thanks-for-order__back' onClick={() => navigate('/')}>
                    На главную
                </button>
            </div>
        </>
    )
}

import axios from 'axios'

export const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(async req => {
    if(req.headers) {
        req.headers['Company-Access-Key'] = 'lA8YqeCP6nxog80g15nKGlSz4VmZeWoThppQGOvvfrwEnVJm7X64lqnqjvlwcVz0'
        req.headers['Loyalaty-Id'] = '6322b63845101d3d889aa6f3'
    }
    return req
})
import React from 'react'
import { Helmet } from 'react-helmet'

export const UserAgreements = () => {
    return (
        <>
            <Helmet>
                <meta
                    name='description'
                    content='Быстрая доставка еды в Красноярске. Неаполитанской пиццы и европеейской кухни.'
                />
                <title>Пользовательское соглашение</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='agreement'>
                <div>
                    <h3>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h3>
                </div>
                <br />
                <p>г. Красноярск</p>
                <p>«14» декабря 2022г</p>
                <br />
                <div>
                    <h4>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ОБЩИЕ ПОЛОЖЕНИЯ</h4>
                </div>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        1.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Настоящее Пользовательское соглашение
                        (далее – Соглашение) относится к сайту Интернет-ресурса «Vita Margarita»,
                        расположенному по адресу{' '}
                        <a href='https://vmdelivery.ru'>https://vmdelivery.ru</a> и ко всем
                        соответствующим сайтам, связанным с сайтом{' '}
                        <a href='https://vmdelivery.ru'>https://vmdelivery.ru</a>
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        1.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Сайт Интернет-ресурса{' '}
                        <a href='https://vmdelivery.ru'>https://vmdelivery.ru</a> (далее – Сайт)
                        является собственностью ООО «ЗАПАД»
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        1.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Настоящее Соглашение регулирует отношения
                        между Администрацией сайта Интернет-ресурса «Vita Margarita», (далее –
                        Администрация сайта) и Пользователем данного Сайта.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        1.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Администрация сайта оставляет за собой
                        право в любое время изменять, добавлять или удалять пункты настоящего
                        Соглашения без уведомления Пользователя.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        1.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Администрация сайта оставляет за собой
                        право в любое время изменять, добавлять или удалять пункты настоящего
                        Соглашения без уведомления Пользователя.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        1.6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Администрация сайта оставляет за собой
                        право в любое время изменять, добавлять или удалять пункты настоящего
                        Соглашения без уведомления Пользователя.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <div>
                    <h4>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ОПРЕДЕЛЕНИЯ ТЕРМИНОВ</h4>
                </div>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        2.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Перечисленные ниже термины имеют для целей
                        настоящего Соглашения следующее значение:
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        2.1.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; «Vita Margarita» – Интернет-ресурс,
                        расположенный на доменном имени
                        <a href='https://vmdelivery.ru'>https://vmdelivery.ru</a> осуществляющий
                        свою деятельность посредством Интернет-ресурса и сопутствующих ему сервисов.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        2.1.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Интернет-ресурс – сайт, содержащий
                        информацию о Товарах, Продавце, позволяющий осуществить выбор, заказ и (или)
                        приобретение Товара.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        2.1.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Администрация сайта Интернет-ресурса –
                        уполномоченные сотрудники на управления Сайтом, действующие от имени ООО
                        «ЗАПАД».
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        2.1.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Пользователь сайта Интернет-ресурса
                        (далее ‑ Пользователь) – лицо, имеющее доступ к Сайту, посредством сети
                        Интернет и использующее Сайт.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        2.1.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Содержание сайта Интернет-ресурса
                        (далее – Содержание) - охраняемые результаты интеллектуальной деятельности,
                        включая тексты документов, названия товаров, предисловия, аннотации, статьи,
                        иллюстрации, обложки, графические, текстовые, фотографические, производные,
                        составные и иные произведения, пользовательские интерфейсы, визуальные
                        интерфейсы, названия товарных знаков, логотипы, базы данных, а также дизайн,
                        структура, выбор, координация, внешний вид, общий стиль и расположение
                        данного Содержания, входящего в состав Сайта и другие объекты
                        интеллектуальной собственности все вместе и/или по отдельности, содержащиеся
                        на сайте Интернет-ресурса.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <div>
                    <h4>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ПРЕДМЕТ СОГЛАШЕНИЯ</h4>
                </div>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        3.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Предметом настоящего Соглашения является
                        предоставление Пользователю Интернет-ресурса доступа к содержащимся на Сайте
                        Товарам и оказываемым услугам.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        3.1.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Интернет-ресурс предоставляет
                        Пользователю следующие виды услуг (сервисов):
                    </span>
                </p>

                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;доступ
                    к электронному контенту на безвозмездной основе, с правом приобретения
                    (скачивания), просмотра контента;
                </p>

                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;доступ
                    к средствам поиска и навигации Интернет-ресурса;
                </p>

                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;предоставление
                    Пользователю возможности размещения сообщений, комментариев, рецензий
                    Пользователей, выставления оценок контенту Интернет-ресурса;{' '}
                </p>

                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;доступ
                    к информации о Товаре и к информации о приобретении Товара на безвозмездной
                    основе;{' '}
                </p>

                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;иные
                    виды услуг, реализуемые на страницах Интернет-ресурса, включая платные услуги.
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        3.1.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Под действие настоящего Соглашения
                        подпадают все существующие (реально функционирующие) на данный момент услуги
                        (сервисы) Интернет-ресурса, а также любые их последующие модификации и
                        появляющиеся в дальнейшем дополнительные услуги (сервисы) Интернет-ресурса.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        3.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Доступ к Интернет-ресурсу предоставляется
                        на безвозмездной основе.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        3.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Настоящее Соглашение является публичной
                        офертой. Получая доступ к Сайту, Пользователь считается присоединившимся к
                        настоящему Соглашению.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        3.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Настоящее Соглашение является публичной
                        офертой. Получая доступ к Сайту, Пользователь считается присоединившимся к
                        настоящему Соглашению.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>

                <div>
                    <h4>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ПРАВА И ОБЯЗАННОСТИ СТОРОН</h4>
                </div>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <b>4.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Администрация сайта вправе:</b>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.1.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Изменять правила пользования Сайтом, а
                        также изменять содержание данного Сайта. Изменения вступают в силу с момента
                        публикации новой редакции Соглашения на Сайте.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.1.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ограничить доступ к Сайту в случае
                        нарушения Пользователем условий настоящего Соглашения.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.1.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Изменять размер оплаты, взимаемый за
                        предоставление доступа к использованию сайта Интернет-ресурса. Изменение
                        стоимости не будет распространяться на Пользователей, имеющих регистрацию к
                        моменту изменения размера оплаты, за исключением случаев, особо оговоренных
                        Администрацией сайта интернет-ресурса.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.1.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Собирать, анализировать, использовать,
                        делиться (в том числе на платной основе) информацией о Пользователе,
                        содержащейся на Сайте, включая, но не ограничиваясь, информацией о
                        контактных и личных данных Пользователя, информацией о действиях
                        Пользователя на Сайте и.т.д.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>

                <p>
                    <b>4.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Пользователь вправе:</b>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.2.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Получить доступ к использованию Сайта.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.2.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Пользоваться всеми имеющимися на Сайте
                        услугами, а также приобретать любые Товары, предлагаемые на Сайте.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.2.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Задавать любые вопросы, относящиеся к
                        услугам Интернет-ресурса по реквизитам, которые находятся в разделе Сайта.
                    </span>
                </p>
                <p>
                    <span>
                        4.2.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Пользоваться Сайтом исключительно в
                        целях и порядке, предусмотренных Соглашением и не запрещенных
                        законодательством Российской Федерации.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <b>4.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Пользователь Сайта обязуется:</b>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.3.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Предоставлять по запросу Администрации
                        сайта дополнительную информацию, которая имеет непосредственное отношение к
                        предоставляемым услугам данного Сайта.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.3.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Соблюдать имущественные и
                        неимущественные права авторов и иных правообладателей при использовании
                        Сайта.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.3.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Не предпринимать действий, которые
                        могут рассматриваться как нарушающие нормальную работу Сайта.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.3.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Не распространять с использованием
                        Сайта любую конфиденциальную и охраняемую законодательством Российской
                        Федерации информацию о физических либо юридических лицах.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.3.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Избегать любых действий, в результате
                        которых может быть нарушена конфиденциальность охраняемой законодательством
                        Российской Федерации информации.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.3.6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Не использовать Сайт для
                        распространения информации рекламного характера, иначе как с согласия
                        Администрации сайта.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.3.7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Не использовать сервисы сайта
                        Интернет-ресурса с целью:
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.3.7.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; загрузки контента, который является
                        незаконным, нарушает любые права третьих лиц; пропагандирует насилие,
                        жестокость, ненависть и (или) дискриминацию по расовому, национальному,
                        половому, религиозному, социальному признакам; содержит недостоверные
                        сведения и (или) оскорбления в адрес конкретных лиц, организаций, органов
                        власти.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.3.7.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; побуждения к совершению
                        противоправных действий, а также содействия лицам, действия которых
                        направлены на нарушение ограничений и запретов, действующих на территории
                        Российской Федерации.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.3.7.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; нарушения прав несовершеннолетних лиц
                        и (или) причинение им вреда в любой форме.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>4.3.7.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ущемления прав меньшинств.</span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.3.7.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; представления себя за другого
                        человека или представителя организации и (или) сообщества без достаточных на
                        то прав, в том числе за сотрудников данного Интернет-ресурса.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.3.7.6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; некорректного сравнения Товара, а
                        также формирования негативного отношения к лицам, (не) пользующимся
                        определенными Товарами, или осуждения таких лиц.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.3.7.7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; введения в заблуждение относительно
                        свойств и характеристик какого-либо Товара из каталога Интернет-ресурса,
                        размещенного на Сайте.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <b>4.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Пользователю запрещается:</b>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.4.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Использовать любые устройства,
                        программы, процедуры, алгоритмы и методы, автоматические устройства или
                        эквивалентные ручные процессы для доступа, приобретения, копирования или
                        отслеживания содержания Сайта данного Интернет-ресурса;
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.4.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Нарушать надлежащее функционирование
                        Сайта;
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.4.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Любым способом обходить навигационную
                        структуру Сайта для получения или попытки получения любой информации,
                        документов или материалов любыми средствами, которые специально не
                        представлены сервисами данного Сайта;
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.4.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Несанкционированный доступ к функциям
                        Сайта, любым другим системам или сетям, относящимся к данному Сайту, а также
                        к любым услугам, предлагаемым на Сайте;
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.4.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Нарушать систему безопасности или
                        аутентификации на Сайте или в любой сети, относящейся к Сайту.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.4.6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Выполнять обратный поиск, отслеживать
                        или пытаться отслеживать любую информацию о любом другом Пользователе Сайта.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        4.4.7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Использовать Сайт и его Содержание в
                        любых целях, запрещенных законодательством Российской Федерации, а также
                        подстрекать к любой незаконной деятельности или другой деятельности,
                        нарушающей права интернет-ресурса или других лиц.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <div>
                    <h4>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ИСПОЛЬЗОВАНИЕ САЙТА ИНТЕРНЕТ-РЕСУРСА</h4>
                </div>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        5.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Сайт и Содержание, входящее в состав
                        Сайта, принадлежит и управляется Администрацией сайта.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        5.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Содержание Сайта не может быть
                        скопировано, опубликовано, воспроизведено, передано или распространено любым
                        способом, а также размещено в глобальной сети «Интернет» без
                        предварительного письменного согласия Администрации сайта.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        5.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Содержание Сайта защищено авторским
                        правом, законодательством о товарных знаках, а также другими правами,
                        связанными с интеллектуальной собственностью, и законодательством о
                        недобросовестной конкуренции.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        5.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; При использовании некоторых сервисов
                        сайта, может потребоваться создание учётной записи Пользователя.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        5.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Пользователь несет персональную
                        ответственность за сохранение конфиденциальности информации учётной записи,
                        включая пароль, а также за всю без исключения деятельность, которая ведётся
                        от имени Пользователя учётной записи.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        5.6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Пользователь должен незамедлительно
                        уведомить Администрацию сайта о несанкционированном использовании его
                        учётной записи или пароля или любом другом нарушении системы безопасности.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        5.7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Настоящее Соглашение распространяет свое
                        действия на все дополнительные положения и условия о покупке Товара и
                        оказании услуг, предоставляемых на Сайте.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        5.8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Информация, размещаемая на Сайте не
                        должна истолковываться как изменение настоящего Соглашения.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        5.9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Администрация сайта имеет право в любое
                        время без уведомления Пользователя вносить изменения в перечень Товаров и
                        услуг, предлагаемых на Сайте, и (или) в цены, применимые к таким Товарам по
                        их реализации и (или) оказываемым услугам Интернет-ресурсом.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <div>
                    <h4>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ОТВЕТСТВЕННОСТЬ</h4>
                </div>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        6.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Любые убытки, которые Пользователь может
                        понести в случае умышленного или неосторожного нарушения любого положения
                        настоящего Соглашения, а также вследствие несанкционированного доступа к
                        коммуникациям другого Пользователя, Администрацией сайта не возмещаются.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        6.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Администрация сайта не несет
                        ответственности за:
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        6.2.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Задержки или сбои в процессе совершения
                        операции, возникшие вследствие непреодолимой силы, а также любого случая
                        неполадок в телекоммуникационных, компьютерных, электрических и иных смежных
                        системах.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        6.2.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Действия систем переводов, банков,
                        платежных систем и за задержки связанные с их работой.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        6.2.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Надлежащее функционирование Сайта, в
                        случае, если Пользователь не имеет необходимых технических средств для его
                        использования, а также не несет никаких обязательств по обеспечению
                        пользователей такими средствами.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        6.2.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Содержание (контент) информации
                        размещенной на Сайте.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        6.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Пользователь самостоятельно несет
                        ответственность за интерпретацию и использование контента (информации),
                        размещенного на Сайте.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <div>
                    <h4>
                        7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; НАРУШЕНИЕ УСЛОВИЙ ПОЛЬЗОВАТЕЛЬСКОГО
                        СОГЛАШЕНИЯ
                    </h4>
                </div>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        7.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Администрация сайта вправе раскрыть любую
                        собранную о Пользователе данного Сайта информацию, если раскрытие необходимо
                        в связи с расследованием или жалобой в отношении неправомерного
                        использования Сайта либо для установления (идентификации) Пользователя,
                        который может нарушать или вмешиваться в права Администрации сайта или в
                        права других Пользователей Сайта.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        7.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Администрация сайта имеет право раскрыть
                        любую информацию о Пользователе, которую посчитает необходимой для
                        выполнения положений действующего законодательства или судебных решений,
                        обеспечения выполнения условий настоящего Соглашения, защиты прав или
                        безопасности название организации, Пользователей.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        7.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Администрация сайта имеет право раскрыть
                        информацию о Пользователе, если действующее законодательство Российской
                        Федерации требует или разрешает такое раскрытие.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        7.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Администрация сайта вправе без
                        предварительного уведомления Пользователя прекратить и (или) заблокировать
                        доступ к Сайту, если Пользователь нарушил настоящее Соглашение или
                        содержащиеся в иных документах условия пользования Сайтом, а также в случае
                        прекращения действия Сайта либо по причине технической неполадки или
                        проблемы.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        7.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Администрация сайта не несет
                        ответственности перед Пользователем или третьими лицами за прекращение
                        доступа к Сайту в случае нарушения Пользователем любого положения настоящего
                        Соглашения или иного документа, содержащего условия пользования Сайтом.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <div>
                    <h4>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;РАЗРЕШЕНИЕ СПОРОВ</h4>
                </div>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        8.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;В случае возникновения любых разногласий
                        или споров между Сторонами настоящего Соглашения обязательным условием до
                        обращения в суд является предъявление претензии (письменного предложения о
                        добровольном урегулировании спора).
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        8.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Получатель претензии письменно уведомляет
                        заявителя претензии о результатах рассмотрения претензии.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        8.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;При невозможности разрешить спор в
                        добровольном порядке любая из Сторон вправе обратиться в суд за защитой
                        своих прав, которые предоставлены им действующим законодательством
                        Российской Федерации.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        8.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Любой иск в отношении условий
                        использования Сайта должен быть предъявлен в течение срока после
                        возникновения оснований для иска, за исключением защиты авторских прав на
                        охраняемые в соответствии с законодательством материалы Сайта. При нарушении
                        условий данного пункта любой иск или основания для иска погашаются исковой
                        давностью.
                    </span>
                </p>
                <p>
                    <span>&nbsp;</span>
                </p>
            </div>
        </>
    )
}
